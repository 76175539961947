<template>
    <div>
        <Header/>
        <Breadcrumbs title="Inscription"/>
        <section class="register-page section-b-space">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h3>{{title}}</h3>
                        <div class="theme-card">
                            <form-wizard title="" subtitle="" shape="tab">
                                <tab-content title="Informations Personnelles" icon="ti-user"
                                             :before-change="()=>validateStep('step1')"
                                >
                                    <step1 :god-son="false" ref="step1" @on-validate="mergePartialModels"></step1>
                                </tab-content>
                                <tab-content title="Supplementaire" icon="ti-check">
                                    <step2 :god-son="false" ref="step2"></step2>
                                </tab-content>
                                <template slot="footer" slot-scope="props">
                                    <div class="wizard-footer-left">
                                        <button v-if="props.activeTabIndex > 0 "
                                                @click="props.prevTab()" class="wizard-footer-right btn btn-solid mt-2">
                                            Precedent
                                        </button>
                                    </div>
                                    <div class="wizard-footer-right">
                                        <button v-if="!props.isLastStep" @click="props.nextTab()"
                                                class="wizard-footer-right btn btn-solid mt-2">Suivant
                                        </button>

                                        <button v-else @click="saveCustomer(false)"
                                                class="wizard-footer-right finish-button btn btn-solid mt-2">
                                            {{props.isLastStep ? "Envoyer" : "Suivant"}}
                                        </button>
                                    </div>
                                </template>
                            </form-wizard>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>
<script>

  import Header from "../../../components/header/appHeader";
  import Footer from "../../../components/footer/appFooter";
  import Breadcrumbs from "../../../components/widgets/breadcrumbs";
  import customerMixin from "../../../mixins/customerMixins";

  export default {
    name: "register",

    mixins: [customerMixin],

    components: {
      Header,
      Footer,
      Breadcrumbs
    },

    data() {
      return {
        title: "Créer un Compte",
        desc: "Inscrivez-vous et devenez un agent promoteur indépendant (API) affilié à Ascension Group. En créant ce compte vous pouvez facilement effectuer vos achats, construire votre propre réseau de promotion et de vente et surtout gagner beaucoup d'argent."
      };
    },

    methods: {

    },

    computed(){
    }

  };
</script>
